import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useState, useEffect } from "react";
import logo from "../assets/img/logo.png"; 
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";


function NavBar() {
    const [activeLink, setActiveLink] = useState("home");
    // change color of bg when scrolling
    const [scrolled, setScrolled] = useState(false);
    //to determine if it's scolled or not, and once scolling
    useEffect(() => {
        const onScroll = () => {
            if(window.scrollY > 50) {
                setScrolled(true);
            } else{
                setScrolled(false);
            }
        }
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    },[])   

    const onUpdateActiveLink = (value) => {
        setActiveLink(value);
    }


    return(
        <Navbar expand="lg" className={scrolled ? "scrolled" : ""}>
            <Container>
                <Navbar.Brand href="#home">
                    <img src={logo} alt="logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav">
                    <span className="navbar-toggle-icon"></span>
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
                    <Nav.Link href="#skills" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('skills')} >Skill</Nav.Link>
                    <Nav.Link href="#projects" className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('projects')} >Project</Nav.Link>
                </Nav>
                <span className="navbar-text">
                    {/* social icon */}
                    <div className="social-icon">
                        <a href="https://www.linkedin.com/in/chelseaguan0219/"><img src={navIcon1} alt="Linkedin-icon" /></a>
                        <a href="https://github.com/Chelseaguan0219"><img src={navIcon2} alt="github-icon" /></a>
                        {/* <a href="#"><img src={navIcon3} alt="" /></a> */}
                    </div>
                    {/* connect form */}
                    <a href="https://www.linkedin.com/in/chelseaguan0219/"><button className="vvd" onClick={() => console.log('connect')}><span> Let's Connect</span></button></a>
                </span>
                </Navbar.Collapse>
            </Container>
        </Navbar>
  );
}

export default NavBar;
