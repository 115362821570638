import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from"../assets/img/pico.jpg";
import { ArrowDownCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';


function Banner() {
    const [loopNum, setLoopNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const [text, setText] = useState('');
    const [delta, setDelta] = useState(300 - Math.random() * 100);
    const [index, setIndex] = useState(1);
    const toRotate = [ "Software Engineer","Frontend Developer", "Web Developer"  ];
    const period = 2000;
    const openResume = () => {
      // Log to the console
      console.log('connect');

      // Open the resume in a new tab
      window.open('/Resume.pdf', '_blank');
  };
  
    useEffect(() => {
      let ticker = setInterval(() => {
        tick();
      }, delta);
  
      return () => { clearInterval(ticker) };
    }, [text])
  
    const tick = () => {
      let i = loopNum % toRotate.length;
      let fullText = toRotate[i];
      let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);
  
      setText(updatedText);
  
      if (isDeleting) {
        setDelta(prevDelta => prevDelta / 2);
      }
  
      if (!isDeleting && updatedText === fullText) {
        setIsDeleting(true);
        setIndex(prevIndex => prevIndex - 1);
        setDelta(period);
      } else if (isDeleting && updatedText === '') {
        setIsDeleting(false);
        setLoopNum(loopNum + 1);
        setIndex(1);
        setDelta(500);
      } else {
        setIndex(prevIndex => prevIndex + 1);
      }
    }
  
    return (
      <section className="banner" id="home">
        <Container>
          <Row className="aligh-items-center">
            <Col xs={12} md={6} xl={7}>
              <TrackVisibility>
                {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <span className="tagline">Welcome to my Planet</span>
                  <h1>{`Hi! I'm Chelsea Guan`} <span className="txt-rotate" dataPeriod="1000" data-rotate='[ "Frontend Developer", "Web Developer", "Software Engineer" ]'><span className="wrap">{text}</span></span></h1>
                    <p>A software engineering student with 3 years of experience in web development and design. Proficient in Java, JavaScript, HTML/CSS, R, SQL, and React, with a strong foundation in statistical analysis.</p>
                    {/* <button onClick={() => console.log('connect')}>Let’s Connect <ArrowRightCircle size={25} /></button> */}
                    <span className="navbar-text">
                    <button className="vvc" onClick={openResume}> Resume <ArrowDownCircle size={25} /></button>
                   </span>
                </div>}
              </TrackVisibility>
            </Col>
            <Col xs={12} md={6} xl={5}>
              <TrackVisibility>
                {({ isVisible }) =>
                  <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                    <img src={headerImg} alt="Header Img"/>
                  </div>}
              </TrackVisibility>
            </Col>
          </Row>
        </Container>
      </section>
    )
}

export default Banner; 